
function BottomInfo() {

  return (
    <div className="w-full bg-gray-50 flex h-16 items-center justify-center whitespace-break-spaces">
            <h2>본 페이지에 모든 정보는 서울 열린데이터 광장을 통해 제공 받습니다.
              문의 : tldudgotla@gmail.com</h2>
    </div>
  )

}

export default BottomInfo;